<script setup lang="ts">
import Loading from "@/components/Loading.vue"
import { useAppStore } from "@/stores/app"
import { RouterView } from "vue-router"

const appStore = useAppStore()
</script>

<template>
  <RouterView />
  <Loading :loading="appStore.isLoading" />
</template>

<style lang="sass">
#app
  overflow: hidden
</style>
