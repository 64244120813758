import { defineStore } from "pinia"
import { ref } from "vue"

export const useAppStore = defineStore("app", () => {
  const isLoading = ref<boolean>(false)
  const showLoader = () => {
    isLoading.value = true
  }
  const hideLoader = () => {
    isLoading.value = false
  }
  return {
    isLoading,
    showLoader,
    hideLoader,
  }
})
